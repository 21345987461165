.giftcard-checkbalance-modal {
  .modal-content {
    background: $white !important;
    @media #{$mobile-only}{
      bottom: 0!important;
      top: unset!important;
      padding-top: 20px;
      height : 424px;
      border-radius: 8px 8px 0px 0px !important;
      text-align: center;
    }
  }.modal-body {
    background: $white !important;
    box-shadow: none !important;
    overflow-y: hidden;
  }
  .modal-header{
    border-bottom: none !important;
  }
  
  .button.custom-modal-close.custom-modal-close-focus{
    padding-left: 200px;
  }
}
 
#CheckBalancepopup{
  @media #{$mobile-only}{
    display: none;
  }
}

.giftcard-getCardDetails-modal {
  .shared-modal-container .modal-content .modal-body {
    background-color: $white;
    box-shadow: none !important;
  }
  

  & .modal-content {
    position: absolute;
    width: 580px;
    height: 527px;
    left: 32%;
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: center;

    @media #{$breakpoint-above-tablet} {
      width: 600px;
      height: 400px;
    }

    @media #{$breakpoint-below-tablet} {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    & .modal-header {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      width: 100%;
      border: none !important;

      @media #{$breakpoint-below-tablet} {
        padding: 3% 1%;
        width: 100%;
        box-shadow: 0 0 black;
      }
    }

    & .header-title {
      @media #{$breakpoint-below-tablet} {
        margin-left: 44px !important;
      }
      font-size: 1.2rem;
      margin: 0 !important;
      padding-left: 20px;
      display: none;
    }

    & button {
      margin-right: -12px;
      padding-top: 10px
    }
    & .modal-body {
      background-color: #fff;
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 25.5px;
      padding-bottom: 78px;
      box-shadow: none;

      @media #{$breakpoint-below-tablet} {
        padding-right: 11px;
        padding-left: 11px;
        padding-top: 20.5px;
        padding-bottom: 68px;
      }
    }
  }
}
.text-danger-phoneNumber{
  margin-top: -10px;
  color:#ff2e00;
}

.getDetailsModalContent {
  @media #{$breakpoint-below-tablet} {
    margin-bottom: 35px;
    width: 83%;
    margin-left: 27px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px
  }

  .GiftbalanceInputArea {
    text-align: left;
    width: 100%;
    height: 303px;
    border-radius: 3px;
    background: #f8f7f5 !important;
    padding-top: 25px;
    padding-left: 19px;
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #202124;

    @media #{$breakpoint-below-tablet} {
      background: #f8f7f5 !important;
      width: auto;
      padding-top: 35px;
      padding-left: 10px;
    }

    .balanceHeader {
      display: flex;
      justify-content: space-between;
      .InputboxHeading {
        font-size: 1.2rem;
      }

      .mandatory-note {
        font-family: $National2Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        color: #494949;
        margin: 0px;
        padding-top: 5px;
        padding-right: 10px;
      }
    }

    .error-text {
      color: #c32a08;
      border-bottom-color: #ff2e00;
      background-color: #f8f7f5;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
    }

    .details {
      font-family: $National2Test;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      background: #f8f7f5;
      color: #202124;
      border: none;
      border-bottom: 1px solid #202124;
      width: 98%;

      .field {
        margin-top: 10px;
        display: flex;
        flex-flow: column-reverse;
        margin-bottom: 1em;
      }
    }
  }
  padding-top: 20px;
  width: 60%;
  margin-left: 120px;
}

.getDetailsModalHeader {
  font-family: $National2Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  width: 100%;

  @media #{$breakpoint-below-tablet} {
    padding: 0% 1%;
    width: 100%;
    box-shadow: 0 0 black;
  }
}

.buttonGiftModal {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 0px 10px;
}

  .blackButton{
    @media #{$mobile-only}{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }

.giftcard-resbal-modal {
  & .modal-content {
    position: absolute;
    width: 580px;
    height: 606px;
    left: 32%;
    overflow-y: scroll;
    overflow-x: hidden;

    @media #{$breakpoint-above-tablet} {
      width: 580px;
    }

    @media #{$breakpoint-below-tablet} {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    & .modal-header {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      width: 100%;

      @media #{$breakpoint-below-tablet} {
        padding: 3% 1%;
        width: 100%;
        box-shadow: 0 0 black;
      }
      display: none;
    }

    & .header-title {
      margin: 0 !important;
      padding-left: 20px;
    }

    & .modal-body {
      background-color: #fff;
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 25.5px;
      padding-bottom: 78px;

      @media #{$breakpoint-below-tablet} {
        background-color: #fff;
        padding-right: 11px;
        padding-left: 11px;
        padding-top: 20.5px;
        padding-bottom: 68px;
      }
    }
  }
}

.resultBalModalContent {
  .inputcardNumboxup {
    margin-top: 15px;
  }

  text-align: left;
  width: 100%;
  height: 345px;
  border-radius: 3px;
  background: #f8f7f5;
  padding-top: 25px;
  padding-left: 19px;
  font-family: $National2Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #202124;

  @media #{$breakpoint-below-tablet} {
    width: auto;
    padding-top: 35px;
    padding-left: 10px;
  }

  .details {
    font-family: $National2Test;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    background: #f8f7f5;
    color: #202124;
    border: none;
    border-bottom: 1px solid #202124;
    width: 98%;

    .field {
      font-weight: 500;
      margin-top: 10px;
      display: flex;
      flex-flow: column-reverse;
      margin-bottom: 10px;
    }
  }

  .BalanceText {
    margin-top: 15px !important;
    position: absolute;
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #202124;
  }
}
input#mt-input-phoneNumber {
  background-color: white;
}
.getPhoneNumber {
  text-align: left;
  width: 82%;
  padding-left: 118px;
  padding-top: 20px;
  padding-bottom: 20px;
  @media #{$mobile-only} {
    text-align: left;
    width: 100%;
    padding: 26px 16px;
  }
}

.addPayButton {
  margin-top: 30px;
  text-align: center;

  .resetButton {
    padding-top: 15px;

    u {
      font-family: $National2Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #202124;
    }
  }
  // .whiteButton {
  //   background-color: #f8f7f5;
  // }
}

.checkBal-errorModal {
  .alet-modal .modal-content .modal-header {
    padding: unset;
  }

  .alet-modal .modal-content .modal-body {
    padding: unset;
  }

  .alet-modal .modal-content {
    @media #{$breakpoint-below-tablet} {
      width: 80%;
      left: 10%;
    }

    width: 30%;
    height: 180px;
    left: 36%;
    top: 30%;
    background: #fff;
  }

  .alert-title {
    margin-left: 10px;
    margin-top: 15px;
    font-size: 18px !important;
    margin-right: auto;
    font-family: $National2Test !important;
    text-transform: none !important;
    font-weight: normal !important;
  }

  .blackButton {
    display: none;
  }

  .whiteButton {
    @media #{$breakpoint-below-tablet} {
      width: 90px !important;
    }

    @media #{$breakpoint-above-tablet} {
      width: 110px !important;
    }

    margin-top: 20px;
    height: 35px !important;
    border-radius: unset;
    background-color: red !important;
    border: none;
    color: white;
    padding: unset;
  }

  .alert-action-container {
    margin-top: 30px;
    margin-left: auto;
  }

  .alert-content {
    margin-right: auto;
    padding-left: 10px;
    margin-top: 20px;
  }
}