.PaymentFailedScreen {
	.header {
		background: #fff;
		padding: 2% 2%;
		z-index: 9;
		border-bottom: 1px solid #dbdbdb;

		.headerLogo {
			margin-left: 0;
			margin-right: 10px
		}
	}

	.paymentFailed {
		display: flex;
		justify-content: center;
		height: 495px;
		width: 100%;
		left: 0;
		top: 100px;
		border-radius: 0;
		border-top: 1px solid #dbdbdb;

		@media #{$breakpoint-below-tablet} {
			padding-left: 7px;
		}

		.FailedDisplay {
			margin-top: 99px;
		}

		.Failimage {
			text-align: center;
		}

		.Reason {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 19px;
			line-height: 30px;
			text-align: center;
			letter-spacing: -0.1px;
			color: $Brand-Black;
			margin-bottom: 45px;
		}

		.backToCart {
			text-align: center;
		}

		.notRight {
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 44px;
			line-height: 58px;
			text-align: center;
			text-transform: uppercase;
			color: $Brand-Black;
			margin-top: 29.26px;
			margin-bottom: 10px;
		}
		.notRightGiftCard {
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: 700;
			font-size: 48px;
			line-height: 48px;
			letter-spacing: 1px;
			text-align: center;
			text-transform: uppercase;
			color: $Brand-Black;
			margin-top: 29.26px;
			margin-bottom: 10px;
			@media #{$breakpoint-below-tablet} {
				font-size: 36px;
				line-height: 36px;
			}
		}

		.back-To-Cart {
			margin-bottom: 90px;
		}
	}

	&.au {
		.paymentFailed {
			.FailedDisplay {
				margin-top: 99px;

				@media #{$breakpoint-below-tablet} {
					margin-top: 40px;
				}
			}

			.Reason {
				margin: auto;
				margin-bottom: 45px;
				font-family: $National2Regular;
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
			}
			.ReasonGiftCard {
				margin: auto;
    			font-family: $National2Regular;
    			font-style: normal;
    			font-weight: 400;
    			font-size: 18px;
    			margin-top: 20px;
    			margin-bottom: 20px;
				@media #{$breakpoint-below-tablet} {
					font-size: 14px;
					line-height: 21px;
				}
			}
		}
	}
}
